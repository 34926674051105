<template>
  <div class="body_">
    <my-fram>
      <div slot="default" class="content">
        <div class="top">
          <div class="top_left">当前位置：列支记录</div>
          <div class="top_right">
            <div class="button" @click="goQuery">返回</div>
          </div>
        </div>
        <div class="foot">
          <div class="head">
            <el-row class="elrow" justify="center">
              <el-col :span="1" class="elcol">序号</el-col>
              <el-col :span="4" class="elcol">维修项目名称</el-col>
              <el-col :span="2" class="elcol">使用类型</el-col>
              <el-col :span="3" class="elcol">维修规模</el-col>
              <el-col :span="3" class="elcol">维修对象</el-col>
              <el-col :span="2" class="elcol">维修状态</el-col>
              <el-col :span="2" class="elcol">维修总额</el-col>
              <el-col :span="2" class="elcol">批次号</el-col>
              <el-col :span="2" class="elcol">分摊金额</el-col>
              <el-col :span="4" class="elcol">创建时间</el-col>
            </el-row>
          </div>
          <div class="one">
            <el-row class="ct" v-for="(item,index) in tableData">
              <el-col :span="1" class="elcol">{{ index + 1 }}</el-col>
              <el-col :span="4" class="elcol">{{item.name}}</el-col>
              <el-col :span="2" class="elcol">{{item.type}}</el-col>
              <el-col :span="3" class="elcol">{{item.scale}}</el-col>
              <el-col :span="3" class="elcol">{{item.object}}</el-col>
              <el-col :span="2" class="elcol"
                      :style="'color: #FA6400;'+ (index == 0 ?  'color: #FA6400;' : 'color: #10C914;') ">
                {{ item.status == 1 ? '未划拨' : '已划拨' }}
              </el-col>
              <el-col :span="2" class="elcol">{{item.orderAmt}}</el-col>
              <el-col :span="2" class="elcol">{{item.batNo}}</el-col>
              <el-col :span="2" class="elcol">{{item.money}}</el-col>
              <el-col :span="4" class="elcol">{{item.createTime}}</el-col>
          <!--    <el-button type="text" @click="dialogVisible = true">
                <span class="xq">详情</span>
              </el-button>-->
              <el-dialog
                class="fakeDialog"
                title="项目详情"
                :visible.sync="dialogVisible"
                width="66%"
                :before-close="close"
                :show-close="false">
                <el-descriptions class="margin-top" title="项目基础信息" :column="4" :size="size">
                  <template slot="extra">
                  </template>
                  <el-descriptions-item label="维修小区名称" :span="4">欣欣茗园</el-descriptions-item>
                  <el-descriptions-item label="使用类型">一般维修</el-descriptions-item>
                  <el-descriptions-item label="项目名称">更换电梯</el-descriptions-item>
                  <el-descriptions-item label="项目规模">大规模</el-descriptions-item>
                  <el-descriptions-item label="维修对象">电梯</el-descriptions-item>
                  <el-descriptions-item label="维修方案描述">1号楼2单元电梯已过质保期发生坠落损坏，需重新更换厂家购买新电梯。</el-descriptions-item>
                </el-descriptions>
                <el-descriptions class="margin-top" title="预分摊信息预览" :column="3" :size="size">
                  <template slot="extra">
                  </template>
                  <el-descriptions-item label="预算金额">600000.00元</el-descriptions-item>
                  <el-descriptions-item label="分摊计算方式">按面积分摊</el-descriptions-item>
                  <el-descriptions-item label="施工方法书">在线预览</el-descriptions-item>
                  <el-descriptions-item label="楼盘信息">一号楼二单元；户室合计44户；建筑面积：6000㎡；</el-descriptions-item>
                </el-descriptions>
                <el-descriptions class="margin-top" title="预分摊信息预览" :column="2" :size="size">
                  <template slot="extra">
                  </template>
                  <el-descriptions-item label="投票事项类型">维修项目投票</el-descriptions-item>
                  <el-descriptions-item label="投票事项名称">更换电梯</el-descriptions-item>
                  <el-descriptions-item label="投票起始日期">2022-06-02 15:49:20</el-descriptions-item>
                  <el-descriptions-item label="投票截止日期">2022-06-06 15:49:20</el-descriptions-item>
                  <el-descriptions-item label="投票选项">同意,不同意,弃权</el-descriptions-item>
                  <el-descriptions-item label="是否自动公示">否</el-descriptions-item>
                </el-descriptions>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false" class="dialog_button">返回</el-button>
                </span>
              </el-dialog>

            </el-row>
          </div>
        </div>
      </div>
    </my-fram>
  </div>
</template>

<script>
import MyFram from '@/components/myFram'

export default {
  name: 'ExpendRecord',
  components: {MyFram},
  data() {
    return {
      dialogVisible: false,
      url:this.$Config.base_server,
      tableData:[
        /*{
          name:'我的家元',
          type:'住宅',
          scale:'大规模',
          object:'电梯',
          status:1,
          orderAmt:234,
          batNo:23545254,
          money:34546,
          createTime:'2022-05-03 02:03:09'
        },*/
      ]
    }
  },

  mounted() {
    var idCard= this.$utils.getIdcard();
    if (idCard==""){
      this.$alert("请将身份证放识别区","消息提示");
      return false;
    }
    this.$axios.post(this.url+'/QueryexpendRecord?idCard='+idCard).then((res)=>{
      if (res.data.resp_code=='0'){
        this.tableData=res.data.datas;
      }else{
        this.$message.error('对不起,查询出错了');
      }
    })

  },
  methods: {
    goQuery() {
      this.$router.push({path: '/query'})
    }
  }
}
</script>

<style lang="stylus">
.body_ {
  height: 100%;
  width: 100%;
}

.xq {
  background-image: linear-gradient(180deg, #E0E7FF 0%, #BFCFFF 100%);
  border: 0.0625rem solid #FFFFFF;
  border-radius: 0.25rem
  margin-right 0.625rem
  padding 0.25rem 0.625rem
  color: #162E85;
}

.bdpz {
  border: 0.0625rem solid #FFFFFF;
  border-radius: 0.25rem;
  margin-right 0.625rem
  color: #D95A10;
  padding 0.25rem 0.625rem
}

.content {
  height 100%
  width 100%
  display: flex;
  flex-direction: column;

  .top {
    width 100%
    height 15%
    display flex
    flex-direction row

    .top_left {
      width 65%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction column
      justify-content center
      justify-items center
      color #FFFFFF
    }

    .top_right {
      width 35%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction row
      justify-content right
      align-items center

      .button {
        width 5rem
        height 2.5rem
        line-height 2.4rem
        text-align center
        background-image linear-gradient(to bottom, #BDE4DD,#06B198)
        border-radius: .3rem;
        font-size 1.2rem
        color #fff
        margin-right 1rem
        cursor pointer
      }
    }
  }

  .foot {
    width 100%
    height 85%

    .head {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      border-left: 1px solid #82D5FF80;
      border-top: 1px solid #82D5FF80;
      border-right: 1px solid #82D5FF80;
      height 12%;
      background #019B84
      font-weight: 600;
      font-size: 1.2rem;
      color: #FFFFFF;

      .elrow {
        height 100%
        display flex
        flex-direction row
        align-items center
        justify-content center

        .elcol {
          text-align center
        }
      }
    }

    .one {
      width: 100%;
      height: 88%;
      background #DBEEEC
      display flex
      flex-direction column
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      border-left: 1px solid #82D5FF80;
      border-bottom: 1px solid #82D5FF80;
      border-right: 1px solid #82D5FF80;
      .ct {
        height calc(90% / 7)
        font-size: 1.2rem;
        color: #333333;
        display flex
        flex-direction row
        align-items center
        border-bottom 1px solid #6d979f
        .ct_2 {
          color: #FFF0CA;
        }

        .ct_3 {
          color: #FFFFFF;
          opacity 0.6
        }
         .elcol {
          text-align: center;
        }
      }
    }
  }
}

// 对话框样式
.fakeDialog {

  .el-dialog__header {
    text-align left
    padding-left 0
    border-bottom: 1px solid #e3e8ee;
    background-color #FFFFFF;
    border-top-right-radius 10px
    border-top-left-radius 10px
  }

  .el-dialog__body {
    background-color #FFFFFF;
  }

  .el-dialog__footer {
    background-color #FFFFFF;
    border-bottom-left-radius 10px
    border-bottom-right-radius 10px
    text-align center
  }

}
.el-descriptions{

  .el-descriptions__title{
    text-indent 0px
    color #409EFE
    font-weight lighter
  }
  .el-descriptions__header{
    padding-left 30px
    background-color #F3F9FF
    height 40px
  }
  .el-descriptions__body{
    padding-left 30px
    background-color #FFFFFF
  }

}
.dialog_button{
  background-image: linear-gradient(180deg, #FFFFFF 0%, #E9EDFF 100%);
  border: 1px solid #8E9CC1;
  border-radius: 6px;
}
</style>
